import { request, methods } from "./request";
import { paths } from "../schema/api";

type ClientCompanyGetResponse =
  paths["/client_company/{id}"]["get"]["responses"][200]["schema"];
type ClientCompaniesGetResponse =
  paths["/client_companies"]["get"]["responses"][200]["schema"];
type ClientCompaniesPutParam =
  paths["/client_companies"]["put"]["parameters"]["body"]["client_companies"];
type ClientCompanyPostParam =
  paths["/client_company/{id}"]["post"]["parameters"]["body"]["client_company"];
type ClientCompanyDeleteResponse =
  paths["/client_company/{id}"]["delete"]["responses"][200]["schema"];
class ClientCompany {
  get(
    clientCompanyId: number,
    arg2: string | string[],
    arg3: string = ""
  ): Promise<ClientCompanyGetResponse> {
    let params = {};
    let token = "";
    if (typeof arg2 === "string") {
      token = arg2;
    } else {
      params = { scope: arg2.join(",") };
      token = arg3;
    }
    return request({
      token,
      params,
      resource: `client_company/${clientCompanyId}`,
      method: methods.Get,
    });
  }
  getAll(
    arg1: string[] | string,
    arg2: string = ""
  ): Promise<ClientCompaniesGetResponse> {
    let params = {};
    let token = "";
    if (typeof arg1 === "string") {
      token = arg1;
    } else {
      params = { scope: arg1.join(",") };
      token = arg2;
    }
    return request({
      token,
      params,
      resource: "client_companies",
      method: methods.Get,
    });
  }
  put(clientCompanies: ClientCompaniesPutParam, token: string = "") {
    return request({
      token,
      method: methods.Put,
      resource: "client_companies",
      params: clientCompanies,
    });
  }
  post(id: number, clientCompany: ClientCompanyPostParam, token: string = "") {
    return request({
      token,
      method: methods.Post,
      resource: `client_company/${id}`,
      params: clientCompany,
    });
  }
  delete(id: number, token: string): Promise<ClientCompanyDeleteResponse> {
    return request({
      token,
      resource: `client_company/${id}`,
      method: methods.Delete,
    });
  }
}

export { ClientCompany };
export type {
  ClientCompanyGetResponse,
  ClientCompaniesGetResponse,
  ClientCompaniesPutParam,
};
