import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { FormattedTextField } from "../../components/formatted_text_field";
import { NavigatorBack } from "../../components/navigator_back";
import { createFormValidator, FormValidator } from "../../validation/form";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "チェーン登録",
  titleUpdate: "チェーン更新",
  name: "チェーン名",
  nameKana: "チェーン名カナ",
  submit: "登録",
  delete: "削除",
};

type FormState = {
  validator: FormValidator;
};

type FormProps = {
  chain: definitions["Chain"];
  deletable?: boolean;
  onNameChanged: (name: string) => void;
  onNameKanaChanged: (nameKana: string) => void;
  onSubmit: () => void;
  onDeleteClicked?: () => void;
};

function Form(props: FormProps) {
  const validator = createFormValidator("chain");
  if (props.chain.id !== 0) {
    validator.validateAll(props.chain);
  }
  const [state] = react.useState<FormState>({
    validator,
  });

  return (
    <ui.Grid
      container
      spacing={0}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <ui.Grid container spacing={4}>
        <ui.Grid container item xs={12} spacing={0}>
          <NavigatorBack
            xs={12}
            sm={12}
            label={labels.list}
            href={`/${resources.chain.identifier}/${controls.retrieve.identifier}`}
          />

          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">
              {props.chain.id !== 0 ? labels.titleUpdate : labels.titleCreate}
            </ui.Typography>
          </ui.Grid>
        </ui.Grid>

        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.name}
            id="chain-name-id"
            trim
            singleByteSpace
            label={labels.name}
            onBlur={(e) => {
              state.validator.validate(e.target.value, "name");
              props.onNameChanged(e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.chain.name}
            variant="outlined"
            name="name"
          />
        </ui.Grid>
        <ui.Grid item xs={12} sm={6}>
          <FormattedTextField
            {...state.validator.formProps.name_kana}
            id="chain-namekana-id"
            trim
            singleByteSpace
            label={labels.nameKana}
            onBlur={(e) => {
              state.validator.validate(e.target.value, "name_kana");
              props.onNameKanaChanged(e.target.value);
            }}
            fullWidth
            rows={6}
            defaultValue={props.chain.name_kana}
            variant="outlined"
            name="name"
          />
        </ui.Grid>

        <ui.Grid container item xs={12} spacing={2} justifyContent="flex-start">
          <ui.Grid item>
            <ui.Button
              {...state.validator.submitButtonProps}
              variant="contained"
              color="secondary"
              type="button"
              onClick={(e) => props.onSubmit()}
            >
              {labels.submit}
            </ui.Button>
          </ui.Grid>
          {props.onDeleteClicked && (
            <ui.Grid item>
              <ui.Button
                disabled={!props.deletable}
                variant="contained"
                color="secondary"
                type="submit"
                onClick={props.onDeleteClicked}
              >
                {labels.delete}
              </ui.Button>
            </ui.Grid>
          )}
        </ui.Grid>
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
