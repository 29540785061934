import react from "react";
import * as ui from "@material-ui/core";
import { useParams } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { createStaff } from "../../const/definitions/staff";
import { resources, controls } from "../../const/resource";
import { ChainStoreSelector } from "../../components/chain_store_selector";
import { StaffSelector } from "../../components/staff_selector";
import { NavigatorBack } from "../../components/navigator_back";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  list: "一覧",
  successorStaff: "引き継ぎ先スタッフ",
  submit: "引き継ぎ",
  succeed: "引き継ぎました",
  failed: "引き継ぎに失敗しました",
};

type StoreInheritState = {
  chains: definitions["Chain"][];
  stores: definitions["Store"][];
  storesByStoreId: { [storeId: number]: definitions["Store"] };
  storesIdsByChainId: { [chainId: number]: number[] };
  staff: definitions["Staff"];
  staffs: definitions["Staff"][];
  successorStaff?: definitions["Staff"];
  objectiveVisitCountByStoreId: { [storeId: number]: number | null };
  initialized: boolean;
};
type StoreInheritProps = {
  showGlobalNotification?: (message: string) => void;
};

type StoreInheritParam = {
  id: string;
};

function StoreInherit(props: StoreInheritProps) {
  const params = useParams<StoreInheritParam>();
  const staffId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<StoreInheritState>({
    chains: [],
    stores: [],
    storesByStoreId: {},
    storesIdsByChainId: {},
    staff: createStaff(),
    staffs: [],
    objectiveVisitCountByStoreId: {},
    initialized: false,
  });

  const makeStoresIdsByChainId = (staff: definitions["Staff"]) => {
    const storesIdsByChainId: { [chainId: number]: number[] } = {};
    (staff.stores || []).forEach((store) => {
      if (!storesIdsByChainId[store.chain_id]) {
        storesIdsByChainId[store.chain_id] = [];
      }
      if (!store.is_closed) {
        storesIdsByChainId[store.chain_id].push(store.id);
      }
    });

    return storesIdsByChainId;
  };

  react.useEffect(() => {
    (async () => {
      state.staff = await new rest.Staff().get(staffId, ["Stores"], auth.getToken());
      state.staffs = (await (new rest.Staff()).getAll(["Stores"], auth.getToken()))
        .filter((staff) => staff.client_id === state.staff.client_id && staff.id !== state.staff.id);
      state.stores = (state.staff.stores || []);
      state.storesByStoreId = state.stores
        .reduce((stores, store) => ({ ...stores, [store.id]: store }), {});
      const chains = await (new rest.Chain()).getByClientId(state.staff.client_id, ["Stores"], auth.getToken());
      state.chains = chains
        .flatMap((chain) => (
          state.stores.find(({ chain_id }) => chain.id === chain_id)
            ? { ...chain, stores: (chain.stores || []).filter((store) => !!state.storesByStoreId[store.id]) }
            : []
        ));

      setState({ ...state, initialized: true });
    })();
  }, []);

  const onChainStoreChanged = (storeIds: { [storeId: number]: string }) => {
    state.staff.stores = Object.keys(storeIds).map(
      (id) => (state.storesByStoreId as any)[id]
    );
    const storesIdsByChainId = makeStoresIdsByChainId(state.staff);

    setState({ ...state, storesIdsByChainId });
  };

  const onSuccessorStaffIdChanged = (staffId: number) => {
    const successorStaff = state.staffs.find(({ id }) => id === staffId);
    setState({ ...state, successorStaff });
  };

  const onSubmit = () => {
    if (!state.successorStaff) {
      return;
    }

    const postStaff = { ...state.successorStaff };
    const postStaffStores = postStaff.stores || [];
    Object.keys(state.storesIdsByChainId).forEach((chainId) => {
      const storeIds = state.storesIdsByChainId[parseInt(chainId)];
      storeIds.forEach((storeId) => {
        console.log(storeId, state.storesByStoreId[storeId]);
        postStaffStores.push(state.storesByStoreId[storeId]);
      });
    });
    postStaff.stores = postStaffStores;
    delete postStaff.client;

    new rest.Staff()
      .post(staffId, postStaff, auth.getToken(), { inherited_from_staff_id: state.staff.id })
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
        const { chains } = state;
        setState({ ...state, chains });
      })
      .catch((e) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <ui.Grid container spacing={4}>
          <NavigatorBack
            xs={12}
            sm={12}
            label={labels.list}
            href={`/${resources.staff.identifier}/${controls.retrieve.identifier}`}
          />
          <ui.Grid item xs={12}>
            <ui.Typography variant="h6">{`${state.staff.name}`}</ui.Typography>
          </ui.Grid>
          <ui.Grid item xs={6} sm={6}>
            <StaffSelector
              staffs={state.staffs}
              selectedValue={state.successorStaff?.id || 0}
              onChanged={onSuccessorStaffIdChanged}
              label={labels.successorStaff}
            />
          </ui.Grid>

          <ui.Grid item xs={12}>
            <ChainStoreSelector
              chains={state.chains || []}
              onChange={onChainStoreChanged}
              selectedStoreIdsByChainId={state.storesIdsByChainId}
              objectiveVisitCountByStoreId={state.objectiveVisitCountByStoreId}
              showobjectiveVisitCountConfirm
            />
          </ui.Grid>

          <ui.Grid item xs={12}>
            <ui.Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!state.successorStaff}
              onClick={onSubmit}
            >
              {labels.submit}
            </ui.Button>
          </ui.Grid>
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { StoreInherit };
