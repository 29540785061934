import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { PostCodeInput } from "../../components/post_code_input";
import { MultipleChainSelector } from "../../components/multiple_chain_selector";
import { FormattedTextField } from "../../components/formatted_text_field";
import { NavigatorBack } from "../../components/navigator_back";
import { createFormValidator, FormValidator } from "../../validation/form";

const labels: { [key: string]: string } = {
  list: "一覧",
  titleCreate: "クライアント企業登録",
  titleUpdate: "クライアント企業更新",
  name: "会社名",
  nameKana: "会社名カナ",
  adminName: "担当者名",
  address: "住所",
  phone: "代表電話番号",
  fax: "FAX",
  url1: "リンク1 (任意)",
  url2: "リンク2 (任意)",
  remarks: "備考 (任意)",
  submit: "登録",
  delete: "削除",
};

type FormState = {
  validator: FormValidator;
};

type FormProps = {
  clientCompany: definitions["ClientCompany"];
  chains: definitions["Chain"][];
  deletable?: boolean;
  onNameChanged: (name: string) => void;
  onNameKanaChanged: (nameKana: string) => void;
  onAdminNameChanged: (adminName: string) => void;
  onAddressChanged: (address: string) => void;
  onPhoneChanged: (phone: string) => void;
  onFaxChanged: (fax: string) => void;
  onUrl1Changed: (url1: string) => void;
  onUrl2Changed: (url2: string) => void;
  onRemarksChanged: (remarks: string) => void;
  onPostCodeChanged: (postCode: string) => void;
  onChainChanged: (chainIds: number[]) => void;
  onSubmit: () => void;
  onDeleteClicked?: () => void;
};

function Form(props: FormProps) {
  const validator = createFormValidator("client_company");
  if (props.clientCompany.id !== 0) {
    validator.validateAll(props.clientCompany);
  }
  const [state] = react.useState<FormState>({
    validator,
  });

  return (
    <ui.Grid container spacing={4}>
      <ui.Grid container item xs={12} spacing={0}>
        <NavigatorBack
          xs={12}
          sm={12}
          label={labels.list}
          href={`/${resources.client_company.identifier}/${controls.retrieve.identifier}`}
        />
        <ui.Grid item xs={12}>
          <ui.Typography variant="h6">
            {props.clientCompany.id !== 0
              ? labels.titleUpdate
              : labels.titleCreate}
          </ui.Typography>
        </ui.Grid>
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        <FormattedTextField
          {...state.validator.formProps.name}
          trim
          singleByteSpace
          id="clientcompany-name-id"
          label={labels.name}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name");
            props.onNameChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.name}
          variant="outlined"
          name="name"
        />
      </ui.Grid>
      <ui.Grid item xs={12} sm={6}>
        <FormattedTextField
          {...state.validator.formProps.name_kana}
          trim
          singleByteSpace
          id="clientcompany-namekana-id"
          label={labels.nameKana}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "name_kana");
            props.onNameKanaChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.name_kana}
          variant="outlined"
          name="name_kana"
        />
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        <FormattedTextField
          {...state.validator.formProps.admin_name}
          trim
          singleByteSpace
          id="clientcompany-adminname-id"
          label={labels.adminName}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "admin_name");
            props.onAdminNameChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.admin_name}
          variant="outlined"
          name="admin_name"
        />
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}></ui.Grid>

      <ui.Grid item xs={6} sm={3}>
        <PostCodeInput
          value={props.clientCompany.post_code}
          onChanged={props.onPostCodeChanged}
        />
      </ui.Grid>

      <ui.Grid item xs={12} sm={9}>
        <FormattedTextField
          {...state.validator.formProps.address}
          trim
          singleByteSpace
          id="clientcompany-address-id"
          label={labels.address}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "address");
            props.onAddressChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.address}
          variant="outlined"
          name="address"
        />
      </ui.Grid>
      <ui.Grid item xs={12} sm={6}>
        <FormattedTextField
          {...state.validator.formProps.phone}
          phone
          id="clientcompany-phone-id"
          label={labels.phone}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "phone");
            props.onPhoneChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.phone}
          variant="outlined"
          name="phone"
        />
      </ui.Grid>
      <ui.Grid item xs={12} sm={6}>
        <FormattedTextField
          {...state.validator.formProps.fax}
          phone
          id="clientcompany-fax-id"
          label={labels.fax}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "fax");
            props.onFaxChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.fax}
          variant="outlined"
          name="fax"
        />
      </ui.Grid>

      <ui.Grid item xs={12} sm={6}>
        <FormattedTextField
          {...state.validator.formProps.url_1}
          trim
          id="clientcompany-url1-id"
          label={labels.url1}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "url_1");
            props.onUrl1Changed(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.url_1}
          variant="outlined"
          name="url_1"
        />
      </ui.Grid>
      <ui.Grid item xs={12} sm={6}>
        <FormattedTextField
          {...state.validator.formProps.url_2}
          trim
          id="clientcompany-url2-id"
          label={labels.url2}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "url_1");
            props.onUrl2Changed(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.url_2}
          variant="outlined"
          name="url_2"
        />
      </ui.Grid>
      <ui.Grid item xs={12} sm={12}>
        <ui.TextField
          {...state.validator.formProps.remarks}
          id="clientcompany-remarks-id"
          label={labels.remarks}
          onBlur={(e) => {
            state.validator.validate(e.target.value, "remarks");
            props.onRemarksChanged(e.target.value);
          }}
          fullWidth
          rows={6}
          defaultValue={props.clientCompany.remarks}
          variant="outlined"
          name="remarks"
        />
      </ui.Grid>

      <ui.Grid item xs={12}>
        <MultipleChainSelector
          selectedValues={props.clientCompany.chain_ids}
          chains={props.chains}
          onChange={props.onChainChanged}
        />
      </ui.Grid>

      <ui.Grid container item xs={12} spacing={2} justifyContent="flex-start">
        <ui.Grid item>
          <ui.Button
            {...state.validator.submitButtonProps}
            variant="contained"
            color="secondary"
            type="button"
            onClick={props.onSubmit}
          >
            {labels.submit}
          </ui.Button>
        </ui.Grid>
        {props.onDeleteClicked && (
          <ui.Grid item>
            <ui.Button
              disabled={!props.deletable}
              variant="contained"
              color="secondary"
              type="submit"
              onClick={props.onDeleteClicked}
            >
              {labels.delete}
            </ui.Button>
          </ui.Grid>
        )}
      </ui.Grid>
    </ui.Grid>
  );
}

export { Form };
