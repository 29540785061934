import react from "react";
import * as ui from "@material-ui/core";
import { definitions } from "../schema/api";
import * as auth from "../auth";
import * as rest from "../rest";

const labels: { [key: string]: string } = {
  clientCompany: "クライアント企業選択",
  any: "全てのクライアント企業",
};

interface ClientCompanySelectorProps {
  clientCompanies?: definitions["ClientCompany"][];
  selectedValue: number;
  includeAny?: boolean;
  onChanged: (e: number) => void;
}

type ClientCompanySelectorState = {
  initialized: boolean;
  selectedValue: number;
  clientCompanies: definitions["ClientCompany"][];
};

function clientCompaniesToMenuItems(
  clientCompanies: definitions["ClientCompany"][],
  includeAny: boolean
): JSX.Element[] {
  const doms = [];
  if (includeAny) {
    doms.push(
      <ui.MenuItem key="clientcompany0" value={0}>
        {labels.any}
      </ui.MenuItem>
    );
  }
  for (let i = 0; i < clientCompanies.length; i += 1) {
    const clientCompany = clientCompanies[i];
    doms.push(
      <ui.MenuItem
        key={`clientcompany${clientCompany.id}`}
        value={clientCompany.id}
      >
        {clientCompany.name}
      </ui.MenuItem>
    );
  }
  return doms;
}

function ClientCompanySelector(props: ClientCompanySelectorProps) {
  const [state, setState] = react.useState<ClientCompanySelectorState>({
    initialized: false,
    selectedValue: props.selectedValue,
    clientCompanies: props.clientCompanies || [],
  });

  react.useEffect(() => {
    (async () => {
      state.initialized = true;
      if (!Array.isArray(props.clientCompanies)) {
        state.clientCompanies = await (new rest.ClientCompany()).getAll(auth.getToken());
        const hasSelectedValue = !!state.clientCompanies.find(({ id }) => id === state.selectedValue);
        if (!hasSelectedValue) {
          state.selectedValue = props.includeAny ? 0 : state.clientCompanies[0].id;
        }
        setState(Object.assign({}, state));
        if (!hasSelectedValue) {
          props.onChanged(state.selectedValue);
        }
      }
    })();
  }, []);

  const clientCompanies = props.clientCompanies || state.clientCompanies;

  return (
    <ui.Box>
      <ui.InputLabel id="clientcompany-id-label">
        {labels.clientCompany}
      </ui.InputLabel>
      <ui.Select
        fullWidth
        labelId="clientcompany-id-label"
        id="clientcompany-id"
        name="clientcompany_id"
        value={props.selectedValue || state.selectedValue || (props.includeAny ? 0 : "")}
        onChange={(e: any) => {
          props.onChanged(parseInt(e.target.value, 10));
        }}
      >
        {clientCompaniesToMenuItems(clientCompanies, props.includeAny || false)}
      </ui.Select>
    </ui.Box>
  );
}

export { ClientCompanySelector };
