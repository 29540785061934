import react from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as c from "../../const";
import { roles } from "../../const/role";
import { controls, resources } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Form } from "./form";
import { Loading } from "../../components/loading";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
  deleteConfirm: "このチェーンを削除しますか？",
};

type UpdateState = {
  initialized: boolean;
  chain: definitions["Chain"];
  deletable: boolean;
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const chainId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    initialized: false,
    chain: {
      id: c.api.newRecordId,
      name: "",
      name_kana: "",
    },
    deletable: false,
  });

  react.useEffect(() => {
    if (!state.initialized) {
      (async () => {
        state.initialized = true;
        state.chain = await (new rest.Chain()).get(chainId, ["Stores"], auth.getToken());
        state.deletable = !state.chain.stores?.length;
        setState({ ...state });
      })();
    }
  }, []);

  const onNameChanged = (value: string) => {
    state.chain.name = value;
    setState({ ...state });
  };
  const onNameKanaChanged = (value: string) => {
    state.chain.name_kana = value;
    setState({ ...state });
  };

  const onSubmit = () => {
    new rest.Chain()
      .post(state.chain.id, state.chain, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  const role = auth.getRole();
  const deletePermission = role?.roleName === roles.root.identifier && state.chain.id > 0;
  const navigate = useNavigate();
  const onDeleteClicked = deletePermission ? async () => {
    if (window.confirm(labels.deleteConfirm)) {
      await (new rest.Chain()).delete(state.chain.id, auth.getToken());
      navigate(`/${resources.chain.identifier}/${controls.retrieve.identifier}`);
    }
  } : undefined;

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <Form
        chain={state.chain}
        deletable={state.deletable}
        onNameChanged={onNameChanged}
        onNameKanaChanged={onNameKanaChanged}
        onSubmit={onSubmit}
        onDeleteClicked={onDeleteClicked}
      />
    </ui.Container>
  );
}

export { Update };
