import { request, methods } from "./request";
import { paths, definitions } from "../schema/api";

type StoreGetIdResponse =
  paths["/store/{id}"]["get"]["responses"][200]["schema"];
type StoresGetParam = paths["/stores"]["get"]["parameters"]["query"];
type StoresGetResponse = definitions["Store"][];
type StoresPutParam = paths["/stores"]["put"]["parameters"]["body"]["stores"];
type StorePostParam =
  paths["/store/{id}"]["post"]["parameters"]["body"]["store"];
type StorePostResponse =
  paths["/store/{id}"]["post"]["responses"][200]["schema"];
type StoreAssociationsGetParam =
  paths["/store_associations"]["get"]["parameters"]["query"];
type StoreAssociationsPostParam =
  paths["/store_associations"]["post"]["parameters"]["body"]["associations"];
type StoreDeleteResponse =
  paths["/store/{id}"]["delete"]["responses"][200]["schema"];

class Store {
  get(id: number, token: string = ""): Promise<StoreGetIdResponse> {
    return request({
      token,
      method: methods.Get,
      resource: `store/${id}`,
    });
  }
  getByIds(ids: number[], token: string = ""): Promise<StoresGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "stores",
      params: { ids: ids.join(",") },
    });
  }
  getByChainId(
    chainId: number,
    arg2: string,
    arg3?: string
  ): Promise<StoresGetResponse> {
    const params: StoresGetParam = { chain_id: chainId };
    const headers: { Accept?: string } = {};
    let token = arg3;
    if (token === undefined) {
      token = arg2;
    } else {
      params.format = arg2;
      if (params.format === "csv") {
        headers.Accept = "text/csv";
      }
    }
    return request({
      token,
      headers,
      params,
      method: methods.Get,
      resource: "stores",
    });
  }
  getByClientId(
    clientId: number,
    token: string = ""
  ): Promise<StoresGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "stores",
      params: { client_id: clientId },
    });
  }
  getAll(token: string = ""): Promise<StoresGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "stores",
    });
  }
  getAllByChainIds(
    chainIds: number[],
    token: string = ""
  ): Promise<StoresGetResponse> {
    return request({
      token,
      method: methods.Get,
      resource: "stores",
      params: { client_ids: chainIds.join(",") },
    });
  }
  put(stores: StoresPutParam, token: string = "") {
    return request({
      token,
      method: methods.Put,
      resource: "stores",
      params: stores,
    });
  }
  post(
    id: number,
    store: StorePostParam,
    token: string = ""
  ): Promise<StorePostResponse> {
    return request({
      token,
      method: methods.Post,
      resource: `store/${id}`,
      params: store,
    });
  }
  postAssociations(
    associations: StoreAssociationsPostParam,
    token: string = ""
  ) {
    return request({
      token,
      method: methods.Post,
      resource: "store_associations",
      params: associations,
    });
  }
  getAssociations(ext: string, arg2: number | string, arg3: string = "") {
    const params: StoreAssociationsGetParam = {
      ext,
    };
    let token = arg3;
    if (typeof arg2 === "number") {
      params.client_id = arg2;
    } else {
      token = arg2;
    }

    const headers: { Accept?: string } = {};
    if (ext === "csv") {
      headers.Accept = "text/csv";
    }

    return request({
      token,
      params,
      headers,
      method: methods.Get,
      resource: "store_associations",
    });
  }
  delete(id: number, token: string): Promise<StoreDeleteResponse> {
    return request({
      token,
      resource: `store/${id}`,
      method: methods.Delete,
    });
  }
}

export { Store };
export type {
  StoreGetIdResponse,
  StoresGetResponse,
  StoresPutParam,
  StorePostParam,
};
