import react from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as ui from "@material-ui/core";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { Loading } from "../../components/loading";
import { Form } from "./form";
import { roles } from "../../const/role";
import { controls, resources } from "../../const/resource";

const labels: { [key: string]: string } = {
  succeed: "登録しました",
  failed: "登録に失敗しました",
  deleteConfirm: "この企業を削除しますか？",
};

type UpdateState = {
  clientCompany: definitions["ClientCompany"];
  chains: rest.ChainsGetResponse;
  deletable: boolean;
  initialized: boolean;
};
type UpdateProps = {
  showGlobalNotification?: (message: string) => void;
};

type UpdateParams = {
  id: string;
};

function Update(props: UpdateProps) {
  const params = useParams<UpdateParams>();
  const clientCompanyId = parseInt(params.id as string, 10);

  const [state, setState] = react.useState<UpdateState>({
    clientCompany: {
      id: 0,
      name: "",
      name_kana: "",
      phone: "",
      fax: "",
      admin_name: "",
      post_code: "",
      address: "",
      chain_ids: [],
    },
    chains: [],
    deletable: false,
    initialized: false,
  });

  const onClientCompanyChanged = (
    name:
      | "name"
      | "name_kana"
      | "admin_name"
      | "address"
      | "phone"
      | "fax"
      | "url_1"
      | "url_2"
      | "remarks",
    value: string
  ) => {
    state.clientCompany[name] = value;
    setState({ ...state });
  };

  const onPostCodeChanged = (postCode: string) => {
    state.clientCompany.post_code = postCode;
    setState({ ...state });
  };

  const onChainChanged = (selectedValues: number[]) => {
    state.clientCompany.chain_ids = selectedValues;
    setState({ ...state });
  };

  const onSubmit = () => {
    const clientCompany = new rest.ClientCompany();
    clientCompany
      .post(clientCompanyId, state.clientCompany, auth.getToken())
      .then((ret: object) => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.succeed);
      })
      .catch(() => {
        props.showGlobalNotification &&
          props.showGlobalNotification(labels.failed);
      });
  };

  const role = auth.getRole();
  const deletePermission = role?.roleName === roles.root.identifier && clientCompanyId > 0;
  const navigate = useNavigate();
  const onDeleteClicked = deletePermission ? async () => {
    if (window.confirm(labels.deleteConfirm)) {
      await (new rest.ClientCompany()).delete(state.clientCompany.id, auth.getToken());
      navigate(`/${resources.client_company.identifier}/${controls.retrieve.identifier}`);
    }
  } : undefined;

  react.useEffect(() => {
    if (!state.initialized) {
      (async () => {
        state.initialized = true;
        const [chains, clientCompany] = await Promise.all([
          (new rest.Chain()).getAll(auth.getToken()),
          (new rest.ClientCompany()).get(clientCompanyId, ["Chains"], auth.getToken()),
        ]);
        if (!clientCompany.chain_ids) {
          clientCompany.chain_ids = [];
        }
        state.chains = chains;
        state.clientCompany = clientCompany;

        if (deletePermission) {
          const [clientReaders, clients] = await Promise.all([
            (new rest.ClientReader()).getAllByClientCompanyId(clientCompanyId, auth.getToken()),
            (new rest.Client()).getAllByClientCompanyId(clientCompanyId, auth.getToken()),
          ]);
          state.deletable = !clientReaders.length && !clients.length;
        }

        setState({ ...state });
      })();
    }
  });

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid
        container
        spacing={0}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Form
          clientCompany={state.clientCompany}
          chains={state.chains}
          deletable={state.deletable}
          onNameChanged={(v) => onClientCompanyChanged("name", v)}
          onNameKanaChanged={(v) => onClientCompanyChanged("name_kana", v)}
          onAdminNameChanged={(v) => onClientCompanyChanged("admin_name", v)}
          onAddressChanged={(v) => onClientCompanyChanged("address", v)}
          onPhoneChanged={(v) => onClientCompanyChanged("phone", v)}
          onFaxChanged={(v) => onClientCompanyChanged("fax", v)}
          onUrl1Changed={(v) => onClientCompanyChanged("url_1", v)}
          onUrl2Changed={(v) => onClientCompanyChanged("url_2", v)}
          onRemarksChanged={(v) => onClientCompanyChanged("remarks", v)}
          onPostCodeChanged={onPostCodeChanged}
          onChainChanged={onChainChanged}
          onSubmit={onSubmit}
          onDeleteClicked={onDeleteClicked}
        />
      </ui.Grid>
    </ui.Container>
  );
}

export { Update };
