import { FormattedTextField } from "./formatted_text_field";

const labels: { [key: string]: string } = {
  postCode: "郵便番号",
};

interface PostCodeInputProps {
  value: string;
  error?: boolean;
  helperText?: React.ReactNode;
  onChanged: (postCode: string) => void;
  onBlur?: (event: any) => void;
}

function PostCodeInput(props: PostCodeInputProps) {
  const onChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const postCode = event.target.value;
    const codes = postCode
      .split("-")
      .map((s) => s.replace(/[^0-9]+/g, ""))
      .slice(0, 2);

    if (codes[0]) {
      if (codes[0].length > 3) {
        codes[1] = codes[0].slice(3);
      }
      codes[0] = codes[0].slice(0, 3);
    }
    if (codes[1]) {
      codes[1] = codes[1].slice(0, 4);
    }

    props.onChanged(codes.join("-"));
  };

  return (
    <FormattedTextField
      trim
      singleByteSpace
      id="postcode-id"
      name="post_code"
      label={labels.postCode}
      error={props.error}
      helperText={props.helperText}
      onChange={onChanged}
      onBlur={props.onBlur}
      fullWidth
      rows={6}
      value={props.value}
      variant="outlined"
    />
  );
}

export { PostCodeInput };
