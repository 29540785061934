import react, { useContext } from "react";
import * as ui from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { resources, controls } from "../../const/resource";
import { ChainSelector } from "../../components/chain_selector";
import { RecordList } from "../../components/record_list_2";
import { Loading } from "../../components/loading";
import { Context, ReducerActionPayload, RecordListStore } from "../../context/DataStoreContext";

import NoteAdd from "@material-ui/icons/NoteAdd";
import GetApp from "@material-ui/icons/GetApp";

const labels: { [key: string]: string } = {
  showClosedStore: "閉店済み店舗を表示",
  chainStoreId: "チェーン店舗 ID",
  name: "店舗名",
  nameKana: "店舗名カナ",
  create: "新規登録",
  download: "CSV ダウンロード",
  notFound: "登録されている店舗がありません",
};

type RetrieveState = {
  initialized: boolean;
};

function Retrieve() {
  const navigate = useNavigate();
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
  });
  const { state: contextState, dispatch } = useContext(Context);
  const storeState = contextState?.store;
  const chains = storeState?.chains || [];
  const chainId = storeState?.chainId || 0;
  const stores = storeState?.listParameter.list || [];
  const showClosedStore = storeState?.showClosedStore || false;

  const onRowClicked = auth.isAuthorized(
    resources.store.identifier,
    auth.resourceCrudFlag.update
  )
    ? (store: definitions["Store"]) => {
      navigate(`/${resources.store.identifier}/${controls.update.identifier}/${store.id}`);
    }
    : undefined;

  react.useEffect(() => {
    (async () => {
      if (dispatch) {
        const json: rest.StoresGetResponse = await (new rest.Store()).getByChainId(chainId, auth.getToken());
        const recordList: ReducerActionPayload["SET_LIST"]["list"] = json.map((item) => ({
          ...item,
          chainStoreId: `${`000${item.chain_id}`.slice(-3)}${`00000${item.id}`.slice(-5)}`,
        }));
        dispatch({ type: "SET_LIST", payload: { type: "store", list: recordList } });
      }
    })();
  }, [chainId]);

  const onDownloadClicked = () => {
    new rest.Store().getByChainId(chainId, "csv", auth.getToken());
  };

  const changeChain = (newChainId: number) => {
    if (dispatch) {
      dispatch({ type: "SET_STORE_CHAIN_ID", payload: newChainId });
      dispatch({ type: "SET_LIST_OPTION", payload: { type: "store", parameter: { page: 1 } } });
    }
  };

  react.useEffect(() => {
    setState({ ...state, initialized: true });

    (async () => {
      if (!chains.length && dispatch) {
        const json: rest.ChainsGetResponse = await (new rest.Chain()).getAll(auth.getToken());
        dispatch({ type: "SET_STORE_CHAINS", payload: json });
        json.length && dispatch({ type: "SET_STORE_CHAIN_ID", payload: json[0].id });
      }
    })();
  }, []);

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid item xs={4} sm={3}>
          <ui.FormControlLabel
            control={
              <ui.Checkbox
                value={showClosedStore ? 1 : 0}
                checked={showClosedStore}
                onChange={() => dispatch && dispatch({ type: "SET_STORE_OPTION", payload: { showClosedStore: !showClosedStore } })}
              />
            }
            label={labels.showClosedStore}
          />
        </ui.Grid>
        <ui.Grid item xs={4} sm={3}>
          <ChainSelector
            chains={chains}
            selectedValue={chainId}
            onChange={(e: any) => changeChain(parseInt(e.target.value, 10))}
          />
        </ui.Grid>
        <ui.Grid item xs={4} sm={6}>
          <ui.Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="100%"
          >
            <ui.IconButton onClick={onDownloadClicked}>
              <GetApp />
              <ui.Typography variant="body2">{labels.download}</ui.Typography>
            </ui.IconButton>

            {auth.isAuthorized(
              resources.store.identifier,
              auth.resourceCrudFlag.create
            ) && (
              <ui.IconButton
                onClick={(e: any) =>
                  (window.location.href = `/${resources.store.identifier}/${controls.create.identifier}?chain_id=${chainId}`)
                }
              >
                <NoteAdd />
                <ui.Typography variant="body2">{labels.create}</ui.Typography>
              </ui.IconButton>
            )}
          </ui.Box>
        </ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList
            type="store"
            defaultText={labels.notFound}
            cols={[
              { label: labels.chainStoreId, key: "chainStoreId" },
              { label: labels.name, key: "name" },
              { label: labels.nameKana, key: "name_kana" },
            ]}
            items={showClosedStore ? stores : stores.filter((item) => !item.is_closed)}
            customStyle={(store: RecordListStore) => {
              if (store.is_closed) {
                return { backgroundColor: "#dddddd" };
              }
            }}
            onRowClicked={onRowClicked}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
