import react, { useContext } from "react";
import * as ui from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { resources, controls } from "../../const/resource";
import * as auth from "../../auth";
import * as rest from "../../rest";
import { definitions } from "../../schema/api";
import { ClientCompanySelector } from "../../components/client_company_selector";
import { RecordList } from "../../components/record_list_2";
import { Loading } from "../../components/loading";
import { downloadAsFile } from "../../window";
import { utf8bom } from "../../const/file";
import { Buffer } from "buffer";
import { NoteAdd, Edit, Schedule, Storefront, DoubleArrow } from "@material-ui/icons";
import GetApp from "@material-ui/icons/GetApp";
import { roles } from "../../const/role";
import { Context, ReducerActionPayload } from "../../context/DataStoreContext";

const labels: { [key: string]: string } = {
  create: "新規登録",
  name: "氏名",
  nameKana: "氏名カナ",
  chain: "チェーン名",
  store: "店舗名",
  storeCode: "店舗コード",
  staffName: "スタッフ名",
  edit: "編集",
  workTime: "勤怠確認",
  relationStore: "担当店舗設定",
  inheritStore: "担当店舗引き継ぎ",
  showQuitted: "退職者を表示",
  downloadStoreList: "担当店舗一覧 ダウンロード",
  notFound: "スタッフが登録されていません",
  clientName: "管理者名",
  objectiveVisitCount: "目標訪問数",
  objectiveVisitCountNotDefined: "未設定",
  allClientCompanies: "全て",
};

type RetrieveState = {
  initialized: boolean;
  user?: definitions["User"];
  client?: definitions["Client"];
  chains: definitions["Chain"][];
  filteredStaffs: definitions["Staff"][];
};

function Retrieve() {
  const navigate = useNavigate();
  const { state: contextState, dispatch } = useContext(Context);
  const staffState = contextState?.staff;
  const staffs = staffState?.listParameter.list || [];
  const clientCompanies = staffState?.clientCompanies || [];
  const clientCompanyId = staffState?.clientCompanyId || 0;
  const clients = staffState?.clients || [];
  const showQuitted = staffState?.showQuitted || false;
  const [state, setState] = react.useState<RetrieveState>({
    initialized: false,
    chains: [],
    filteredStaffs: [],
  });
  const role = auth.getRole();
  const roleName = role?.roleName;

  const oEditClicked = (staffId: number) => {
    navigate(`/${resources.staff.identifier}/${controls.update.identifier}/${staffId}`);
  };

  const oWorkTimeClicked = (staffId: number) => {
    navigate(`/${resources.work_time.identifier}/${controls.retrieve.identifier}/${staffId}`);
  };

  const onRelationClicked = (staffId: number) => {
    navigate(`/${resources.staff.identifier}/${resources.store.identifier}/${controls.update.identifier}/${staffId}`);
  };

  const onInheritingClicked = (staffId: number) => {
    navigate(`/${resources.staff.identifier}/${resources.store.identifier}/${controls.inherit.identifier}/${staffId}`);
  };

  const onDownloadClicked = async () => {
    const user = state.user || await (new rest.User()).getMe(["Client"], auth.getToken());
    const clientId = user.client?.id;
    if (!clientId) {
      return;
    }

    const isExistsData = !!state.client;
    const [client, chains] = isExistsData
      ? [state.client, state.chains]
      :
        await Promise.all([
          (new rest.Client()).get(clientId, ["ClientCompany", "Chains", "ClientStores.Store"], auth.getToken()),
          (new rest.Chain()).getByClientId(clientId, ["Stores"], auth.getToken()),
        ]);
    if (!isExistsData) {
      setState({ ...state, client, chains });
    }
    const stores = client?.client_stores || [];
    const rows: string[] = [
      [
        labels.clientName,
        labels.staffName,
        labels.chain,
        labels.store,
        labels.storeCode,
        labels.objectiveVisitCount,
      ].join(","),
    ];
    staffs
      .filter(({ is_quitted }) => !is_quitted)
      .forEach(({ name, stores: staffStores = [] }) => {
        staffStores.forEach((staffStore) => {
          const chain = chains.find(({ id }) => id === staffStore.chain_id);
          const store = stores.find(({ store_id }) => store_id === staffStore.id);
          const clientStore = (client?.client_stores || []).find(({ store_id }) => store_id === staffStore.id);
          rows.push([
            client?.name || "-",
            name,
            chain?.name || "-",
            staffStore.name,
            clientStore?.store_code || "-",
            store?.objective_visit_count || labels.objectiveVisitCountNotDefined,
          ].join(","));
        });
      });

    const utf8CsvBuffer = Buffer.concat([
      Buffer.from(utf8bom),
      Buffer.from(rows.join("\n"), "utf-8"),
    ]);

    downloadAsFile(utf8CsvBuffer, '担当店舗一覧.csv');
  };

  const onClientCompanyChanged = (id: number) => {
    if (dispatch) {
      dispatch({ type: "SET_STAFF_OPTION", payload: { clientCompanyId: id } });
    }
  };

  const onShowingQuittedChanged = () => {
    if (dispatch) {
      dispatch({ type: "SET_STAFF_OPTION", payload: { showQuitted: !showQuitted } });
    }
  };

  const filterStaffs = (params: RetrieveState) => {
    const clientIds = clientCompanyId && clients.flatMap(
      (c) => c.client_company_id === clientCompanyId ? c.id : []
    );
    params.filteredStaffs = staffs.filter(
      (s) => (showQuitted || !s.is_quitted) && (!clientIds || clientIds.includes(s.client_id))
    );
  };

  react.useEffect(() => {
    if (!state.initialized) {
      state.initialized = true;
      (async () => {
        if (dispatch) {
          const recordList = await (new rest.Staff()).getAll(["Stores"], auth.getToken());
          const optionList = roleName === roles.root.identifier
            ? await Promise.all([
              (new rest.ClientCompany()).getAll(auth.getToken()),
              (new rest.Client()).getAll(auth.getToken()),
            ])
            : [[], []];
          const optionPayload: ReducerActionPayload["SET_STAFF_OPTION"] =  {
            clientCompanies: optionList[0] as rest.ClientCompaniesGetResponse,
            clients: optionList[1] as rest.ClientsGetResponse,
          };
          dispatch({ type: "SET_LIST", payload: { type: "staff", list: recordList } });
          dispatch({ type: "SET_STAFF_OPTION", payload: optionPayload });
        }

        filterStaffs(state);
        setState({ ...state });
      })();
    }
  }, []);

  react.useEffect(() => {
    filterStaffs(state);
    setState({ ...state });
  }, [staffs.length, clients.length, clientCompanyId, showQuitted]);

  if (!state.initialized) {
    return <Loading />;
  }

  return (
    <ui.Container>
      <ui.Grid container spacing={4}>
        <ui.Grid item xs={12}></ui.Grid>
        <ui.Grid
          container
          item
          xs={12}
          alignContent="center"
          justifyContent="space-between"
        >
          {roleName === roles.root.identifier && (
            <ui.Grid item xs={6}>
              <ClientCompanySelector
                clientCompanies={clientCompanies}
                selectedValue={clientCompanyId}
                includeAny={true}
                onChanged={onClientCompanyChanged}
              />
            </ui.Grid>
          )}
          <ui.Grid
            container
            xs={roleName === roles.root.identifier ? 6 : 12}
            alignContent="center"
            justifyContent="flex-end"
          >
            <ui.Grid item>
              <ui.FormControlLabel
                control={
                  <ui.Checkbox
                    value={showQuitted ? 1 : 0}
                    checked={showQuitted}
                    onChange={onShowingQuittedChanged}
                  />
                }
                label={labels.showQuitted}
              />
            </ui.Grid>
            <ui.Grid item>
              {auth.isAuthorized(
                resources.staff.identifier,
                auth.resourceCrudFlag.create
              ) && (
                <ui.Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ui.IconButton
                    onClick={(e: any) =>
                      (window.location.href = `/${resources.staff.identifier}/${controls.create.identifier}`)
                    }
                  >
                    <NoteAdd />
                    <ui.Typography variant="body2">{labels.create}</ui.Typography>
                  </ui.IconButton>
                </ui.Box>
              )}
            </ui.Grid>
            {roleName === roles.client.identifier && (
              <ui.Grid item>
                <ui.IconButton onClick={onDownloadClicked}>
                  <GetApp />
                  <ui.Typography variant="body2">{labels.downloadStoreList}</ui.Typography>
                </ui.IconButton>
              </ui.Grid>
            )}
          </ui.Grid>
        </ui.Grid>
        <ui.Grid item xs={12}>
          <RecordList
            type="staff"
            defaultText={labels.notFound}
            cols={[
              { label: labels.name, key: "name" },
              { label: labels.nameKana, key: "name_kana" },
              { label: "", key: "control" },
            ]}
            items={state.filteredStaffs}
            customStyle={(staff: definitions["Staff"]) => {
              if (staff.is_quitted) {
                return { backgroundColor: "#dddddd" };
              }
            }}
            extraKeyItemCallbacks={{
              control: (staff) => (
                <ui.Grid container spacing={1} justifyContent="flex-end">
                  {auth.isAuthorized(
                    resources.staff.identifier,
                    auth.resourceCrudFlag.update
                  ) && (
                    <ui.Grid item>
                      <ui.Button
                        color="primary"
                        onClick={(e: any) => oEditClicked(staff.id)}
                      >
                        <ui.Grid container spacing={1}>
                          <ui.Grid item>
                            <Edit />
                          </ui.Grid>
                          <ui.Grid item>{labels.edit}</ui.Grid>
                        </ui.Grid>
                      </ui.Button>
                    </ui.Grid>
                  )}
                  {auth.isAuthorized(
                    resources.work_time.identifier,
                    auth.resourceCrudFlag.retrieve
                  ) && (
                    <ui.Grid item>
                      <ui.Button
                        color="primary"
                        onClick={(e: any) => oWorkTimeClicked(staff.id)}
                      >
                        <ui.Grid container spacing={1}>
                          <ui.Grid item>
                            <Schedule />
                          </ui.Grid>
                          <ui.Grid item>{labels.workTime}</ui.Grid>
                        </ui.Grid>
                      </ui.Button>
                    </ui.Grid>
                  )}
                  {auth.isAuthorized(
                    resources.staff.identifier,
                    auth.resourceCrudFlag.update
                  ) && (
                    <>
                      <ui.Grid item>
                        <ui.Button
                          color="primary"
                          onClick={(e: any) => onRelationClicked(staff.id)}
                        >
                          <ui.Grid container spacing={1}>
                            <ui.Grid item>
                              <Storefront />
                            </ui.Grid>
                            <ui.Grid item>{labels.relationStore}</ui.Grid>
                          </ui.Grid>
                        </ui.Button>
                      </ui.Grid>
                      {showQuitted && (
                        <ui.Grid item>
                        <ui.Button
                          color="primary"
                          onClick={(e: any) => onInheritingClicked(staff.id)}
                          disabled={!staff.is_quitted}
                        >
                          <ui.Grid container spacing={1}>
                            <ui.Grid item>
                              <DoubleArrow />
                            </ui.Grid>
                            <ui.Grid item>{labels.inheritStore}</ui.Grid>
                          </ui.Grid>
                        </ui.Button>
                      </ui.Grid>
                      )}
                    </>
                  )}
                </ui.Grid>
              ),
            }}
          />
        </ui.Grid>
      </ui.Grid>
    </ui.Container>
  );
}

export { Retrieve };
